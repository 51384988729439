import { type UserDetails } from '~~/server/modules/auth/type';

export type Policy = (user: UserDetails) => boolean;

export type PolicyDefinition<Params extends any[]> = (
	...args: Params
) => Policy;

export const definePolicy = <Params extends any[]>(
	policy: PolicyDefinition<Params>,
): PolicyDefinition<Params> => {
	return policy;
};

export const or = (...policies: Policy[]): Policy => {
	return (user: UserDetails) => {
		return policies.some(policy => policy(user));
	};
};

export const and = (...policies: Policy[]): Policy => {
	return (user: UserDetails) => {
		return policies.every(policy => policy(user));
	};
};

export const not = (policy: Policy): Policy => {
	return (user: UserDetails) => {
		return !policy(user);
	};
};
