import { parseISO } from 'date-fns';
import { definePolicy, and } from './utils';

export const isActive = definePolicy(
	() => user => user.status === 'active',
);

export const isVerified = definePolicy(
	() => user => !!user.emailVerified && parseISO(user.emailVerified) < new Date(),
);

export const isPendingEmailVerification = definePolicy(
	() => user => !user.emailVerified,
);

export const isAppUser = definePolicy(
	() => user => and(isActive(), isVerified())(user),
);
