import {
	isPendingEmailVerification,
	isAppUser,
	isMemberType,
} from '~/utils/policies';

export default defineNuxtRouteMiddleware((to) => {
	const { loggedIn, user } = useUserSession();

	if (!loggedIn.value || !user.value || !user.value?.role) {
		return navigateTo('/login');
	}

	if (isPendingEmailVerification()(user.value)) {
		return navigateTo('/verify');
	}

	if (!isAppUser()(user.value)) {
		return showError(createError({
			statusCode: 403,
			statusMessage: 'Forbidden: App Access Required',
			fatal: true,
		}));
	}

	if (to.path === '/verify' && !isPendingEmailVerification()(user.value)) {
		return navigateTo('/');
	}

	if (to.path.startsWith('/advisor') && !isMemberType('advisor')(user.value)) {
		return showError(createError({
			statusCode: 403,
			statusMessage: 'Forbidden: Advisors Only',
			fatal: true,
		}));
	}

	if (to.path.startsWith('/staff') && !user.value.staff) {
		return showError(createError({
			statusCode: 403,
			statusMessage: 'Forbidden: Staff Only',
			fatal: true,
		}));
	}

	if(to.path === '/'){
		if (!user.value.member && user.value.staff) {
			navigateTo('/staff');
		}

		if (user.value.member && !user.value.staff) {
			navigateTo('/advisor');
		}
	}
});
