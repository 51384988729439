import { definePolicy } from './utils';

export const isMemberType = definePolicy(
	(type: string) => user => user.member?.membership_type.type === type,
);

export const isInChapter = definePolicy(
	(chapter_id: string | undefined) => user => user.member?.affiliation === chapter_id && !!chapter_id,
);

export const isAdvisor = definePolicy(
	() => user =>
		isMemberType('advisor')(user),
);

export const isStudent = definePolicy(
	() => user => isMemberType('student')(user),
);
